import { makeStyles } from '@material-ui/core/styles';
import { BLACK, WHITE } from '@theme_color';

const useStyles = makeStyles((theme) => ({
    containerMobile: {
        width: '100%',
        maxWidth: '580px',
        height: '64px',
        padding: '10px 20px',
        background: '#5719A0',
        color: '#fff',
        margin: 0,
        display: 'none',
        alignItems: 'center',
        alignSelf: 'center',
        justifyContent: 'space-between',
        '& .left-content': {
            display: 'flex',
            margin: 0,
            alignItems: 'center',
            alignSelf: 'center',
            '& .logo_install': {
                marginRight: 10,
            },
        },
    },
    titleMobile: {
        fontWeight: 'bold',
        fontSize: 20,
    },
    iconClose: {
        // width: '10%',
        marginRight: 13,
    },
    iconCloseButton: {
        border: `1px solid ${WHITE}`,
        borderRadius: 100,
        padding: 2,
        fontSize: 8,
        width: '14px',
        height: '14px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    textContainer: {
        // width: '65%',
        '& p': {
            fontSize: 11,
        },
    },
    btnInstallContainer: {
        // width: '15%',
    },
    btnInstall: {
        color: WHITE,
        borderColor: WHITE,
        borderRadius: 100,
        fontSize: 11,
        '&:hover': {
            background: WHITE,
            color: '#5719A0',
        },
    },
    popupInstallDesktop: {
        display: 'none',
        position: 'absolute',
        top: '4px',
        fontSize: 13,
        [theme.breakpoints.up('sm')]: {
            position: 'relative',
            background: '#bc2494',
            margin: '-5px -1.5% 10px -1.5%',
            padding: '5px',
            color: WHITE,
            '& button': {
                background: WHITE,
                color: BLACK,
            },
        },
        [theme.breakpoints.up('md')]: {
            position: 'absolute',
            top: '4px',
            left: '0',
            background: 'unset',
            padding: 'unset',
            color: BLACK,
            margin: 'unset',
            '& button': {
                background: BLACK,
                color: WHITE,
            },
        },
    },
    btnInstallDesktop: {
        fontSize: 11,
    },

    containerMobileFooter: {
        width: '100%',
        maxWidth: '580px',
        height: '47px',
        padding: '7px 20px',
        background: '#CDD7F3',
        color: '#BE1F93',
        margin: 0,
        position: 'fixed',
        bottom: 0,
        display: 'none',
        alignItems: 'center',
        alignSelf: 'center',
        justifyContent: 'space-between',
        '& .left-content': {
            height: '100%',
            display: 'flex',
            margin: 0,
            alignItems: 'center',
            alignSelf: 'center',
            '& .logo_install': {
                marginRight: 10,
            },
        },
    },
    textContainerFooter: {
        fontSize: 14,
    },
    iconCloseButtonFooter: {
        fontSize: 16,
        color: WHITE,
        fontWeight: 'bold',
    },
}));

export default useStyles;
