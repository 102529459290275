/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { installMessage } from '@config';
import useStyles from '@core_modules/theme/components/custom-install-popup/style';
import React, { useEffect } from 'react';
import Cookies from 'js-cookie';

const checkingPlatform = () => {
    if (typeof navigator !== 'undefined') {
        let platform = navigator.userAgent;
        if (platform.toLocaleLowerCase().indexOf('android') > 0) {
            platform = 'android';
        } else if (platform.toLocaleLowerCase().indexOf('iphone') > 0 || platform.toLocaleLowerCase().indexOf('ipad') > 0) {
            platform = 'ios';
        }

        return platform;
    }

    return '';
};

const PopupInstalation = ({ setDisplayed }) => {
    const styles = useStyles();
    const closePopup = () => {
        const el = document.getElementById('popup-install-apps');
        // hidden popup
        if (el) {
            el.style.display = 'none';
            setDisplayed(false);
        }

        const date = new Date();
        // add a day
        date.setDate(date.getDate() + 1);
        localStorage.setItem('hideInstallPopupApps', true);
        localStorage.setItem('expiredHideInstallPopupApps', date.getDate());
    };

    const handleInstallApps = () => {
        setTimeout(() => {
            const platform = checkingPlatform();
            if (platform && platform !== '') {
                if (platform === 'android') {
                    window.location = 'https://play.google.com/store/apps/details?id=app.belanjaku.webview';
                }

                if (platform === 'ios') {
                    window.location = 'https://www.apple.com/id/app-store';
                }
            }
        }, 25);
        window.location = 'scv2://belanjaku.app';
    };

    React.useEffect(() => {
        const platform = checkingPlatform();
        let hidePopup = typeof window !== 'undefined' && localStorage.getItem('hideInstallPopupApps');
        const expiredHidePopup = typeof window !== 'undefined' && localStorage.getItem('expiredHideInstallPopupApps');
        const date = new Date();

        if (expiredHidePopup && expiredHidePopup > date.getDate()) {
            hidePopup = true;
        } else {
            hidePopup = false;
        }

        const { android } = Cookies.getJSON();

        if (platform && platform !== '' && !hidePopup && !android) {
            if (platform === 'android' || platform === 'ios') {
                const el = document.getElementById('popup-install-apps');
                // hidden popup
                if (el) {
                    el.style.display = 'flex';
                }
            }
        }
    }, []);

    useEffect(() => {
        const hasMobileInstall = typeof window !== 'undefined' && document.getElementById('popup-install-apps');
        const isDisplayed = hasMobileInstall && hasMobileInstall.style.display && hasMobileInstall.style.display === 'flex';
        if (isDisplayed) {
            setDisplayed(true);
        }
    }, []);

    const foldOnly = useMediaQuery('(max-width: 350px)');

    return (
        <div
            id="popup-install-apps"
            className={classNames('row', styles.containerMobile)}
            style={{
                padding: foldOnly ? '10px' : '10px 20px',
            }}
        >
            <div className="left-content">
                <div className={styles.iconClose}>
                    <span className={styles.iconCloseButton} onClick={() => closePopup()}>x</span>
                </div>
                <img
                    src="/assets/img/custom_install/logo_bg_white.svg"
                    alt="logo txt"
                    className="logo_install"
                    style={{
                        maxWidth: foldOnly ? 30 : '100%',
                    }}
                />
                <div className={styles.textContainer}>
                    <img
                        src="/assets/img/custom_install/logo_txt.svg"
                        alt="logo txt"
                        style={{
                            maxHeight: foldOnly ? 12 : '100%',
                        }}
                    />
                    <p
                        style={{
                            margin: 0,
                            fontSize: foldOnly ? 8 : 11,
                        }}
                    >
                        {installMessage}

                    </p>
                </div>
            </div>
            <div className={styles.btnInstallContainer}>
                <Button
                    className={styles.btnInstall}
                    variant="outlined"
                    color="primary"
                    style={
                        foldOnly ? {
                            fontSize: 8,
                        } : {}
                    }
                    onClick={handleInstallApps}
                >
                    Download
                </Button>
            </div>
        </div>
    );
};

export default PopupInstalation;
