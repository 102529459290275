/* eslint-disable react/jsx-one-expression-per-line */
import Typography from '@common_typography';
// import { useRouter } from 'next/router';
// import Cookies from 'js-cookie';
// import { checkoutKeyCookies } from '@config';

const Copyright = () => {
    const getYear = new Date().getFullYear();
    // const router = useRouter();

    // let brandName;
    // const config = Cookies.getJSON(checkoutKeyCookies.config);
    // if (config?.brands && config.brands.length > 0) {
    //     brandName = config.brands[0].name;
    // }

    // const isCheckoutPage = router.pathname === '/' || router.pathname === '/login' || router.pathname === '/success';
    // const brand = isCheckoutPage ? brandName || 'Swift Checkout' : 'belanjaku.app';
    const brand = 'Swift Checkout';

    return (
        <div className="copyright">
            <Typography>&copy; {`Copyright ${getYear} - ${brand} | Powered by Swift`}</Typography>
            <style jsx global>
                {`
                    .copyright {
                        text-align: center;
                        padding: 10px;
                    }
                    .copyright span {
                        letter-spacing: 0.03em;
                    }
                `}
            </style>
        </div>
    );
};

export default Copyright;
